import { Dispatch } from 'redux'

import { CLEAR_SESSION, SET_SESSION } from '../constants/actionTypes'
import { SESSION_KEY } from '../constants/storageKeys'
import cognitoUtils from '../lib/cognitoUtils'
import { SessionData } from '../types/session'

const blankSession = {
  isLoggedIn: false,
};

export const clearSession = () => {
  window.localStorage.setItem(SESSION_KEY, JSON.stringify(blankSession));

  return {
    type: CLEAR_SESSION,
  };
}

// Initialise the Cognito sesson from a callback href
export function initSessionFromCallbackURI (callbackHref: string) {
  return function (dispatch: Dispatch) {
    return cognitoUtils.parseCognitoWebResponse(callbackHref) // parse the callback URL
      .then(() => cognitoUtils.getCognitoSession()) // get a new session
      .then((session: any) => ({ ...session, isLoggedIn: true }))
      .then((session) => {
        window.localStorage.setItem(SESSION_KEY, JSON.stringify(session));
        dispatch({ type: SET_SESSION, session });
      });
  }
}

export const setSession = (session: SessionData) => {
  return {
    type: SET_SESSION,
    session,
  };
}
