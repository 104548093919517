import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { clearSession } from '../../actions/session';

export default function SignOut() {
  const dispatch = useDispatch();

  dispatch(clearSession());

  return (
    <Redirect to="/" />
  );
}
