import * as React from 'react'
import Apps from '@invotra/invotra-mdc/lib/widgets/Apps'
import MdcWorkBar from '@invotra/invotra-mdc/lib/widgets/WorkBar'

// TODO: Populate data object
const data = {
  insights: [],
  createItem: [],
  apps: (
    <Apps className="" data={[
      {
        title: 'Companies',
        icon: {
          icon: 'business',
        },
        to: '/companies',
      },
      {
        title: 'Hello World',
        icon: {
          icon: 'emoji_people',
        },
        to: 'hello-world',
      },
    ]} />
  )
}

export default function WorkBar() {
  return <MdcWorkBar data={data} />
}
