import { gql } from 'apollo-boost'

export const createPod = gql`
mutation createPod(
  $companyId: String!
  $title: String!
  $description: String
  $privacySetting: String!
) {
  createPod(
    title: $title
    description: $description
    privacy_setting: $privacySetting
    company: $companyId
  ) {
    id
  }
}
`

export const updatePod = gql`
mutation updatePod(
  $podId: String!
  $title: String!
  $description: String
  $privacySetting: String!
) {
  updatePod(
    id: $podId
    title: $title
    description: $description
    privacy_setting: $privacySetting
  ) {
    id
  }
}
`

export const deletePod = gql`
mutation deletePod($podId: String!) {
  deletePod(id: $podId) {
    id
  }
}
`
