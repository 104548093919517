import * as React from 'react'

import Page from '../../common/Page'

export default function HelloWorld() {
  // TODO: This component doesn't seem to show anything. Fix it.
  console.log('Hello World')

  return (
    <Page title="Hello World" />
  )
}
