import * as React from 'react'
import FieldLegend from '@invotra/invotra-mdc/lib/widgets/FieldLegend'

interface DrawerSectionProps {
  title: string
}

export type DrawerSectionPropT = React.PropsWithChildren<DrawerSectionProps>

export default function DrawerSection({ title, children }: DrawerSectionPropT) {
  return (
    <FieldLegend collapsible title={title}>
      {children}
    </FieldLegend>
  )
}
