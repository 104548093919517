import React from 'react'

import Page from '../../common/Page'
import { useDrawerItems } from '../../../hooks/graphql/companies'

interface CompanyPageProps {
  title?: string
  withPadding?: boolean
  drawerDismissable?: boolean
}

export type CompanyPagePropT = React.PropsWithChildren<CompanyPageProps>

export default function CompaniesPage({
  title,
  withPadding,
  drawerDismissable = false,
  children,
}: CompanyPagePropT) {
  const drawerItems = useDrawerItems()

  return (
    <Page
      title={title}
      drawerTitle="Companies"
      drawerItems={drawerItems}
      drawerDismissable={drawerDismissable}
      withoutPadding={!withPadding}
    >
      {children}
    </Page>
  )
}
