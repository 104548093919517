import { SimpleListItem } from '@invotra/invotra-mdc/lib/widgets/list/ListItem'
import * as React from 'react'
import { Link, LinkProps, useRouteMatch } from 'react-router-dom'

interface DrawerLinkProps {
  to?: LinkProps['to']
}

export type DrawerLinkPropT = React.PropsWithChildren<DrawerLinkProps>

export default function DrawerLink({ to = '#', children }: DrawerLinkPropT) {
  const match = useRouteMatch()
  let active = false

  // @ts-ignore
  if (match.url.startsWith(to.pathname || to)) {
    active = true
  }

  return (
    <Link to={to} className="invotra-admin__drawer__drawer-link">
      <SimpleListItem activated={active} disabled={!to}>
        {children}
      </SimpleListItem>
    </Link>
  )
}
