import React from 'react'

import { useCreatePod } from '../../../../../../../../hooks/graphql/pods'
import AddNew from '../../../../../../../common/AddNew'
import PodForm from '../PodForm'

export interface AddNewPropT {
  defaults: any
  refetch?: Function
}

export default function AddNewPod({ defaults, refetch }: AddNewPropT) {
  return (
    <AddNew
      defaults={defaults}
      hook={useCreatePod}
      label="Create Pod"
      refetch={refetch}
      FormComponent={PodForm}
    />
  )
}
