import { CognitoAuth } from 'amazon-cognito-auth-js'
import { CognitoUserPool } from 'amazon-cognito-identity-js'

export interface AppConfig {
  region: string;
  userPool: string;
  userPoolBaseUri: string;
  clientId: string;
  callbackUri: string;
  signoutUri: string;
  tokenScopes: string[];
  graphqlUri: string;
}

export interface CognitoUserSessionResult {
  accessToken: {
    jwtToken: string;
    payload: {
      auth_time: number;
      client_id: string;
      'cognito:groups': string[];
      exp: number;
      iat: number;
      iss: string;
      jti: string;
      scope: string;
      sub: string;
      token_use: string;
      username: string;
      version: string;
    };
  };
  idToken: {
    jwtToken: string;
    payload: {
      at_hash: string;
      aud: string;
      'cognito:groups': string[];
      'cognito:username': string;
      email: string;
      email_verified: boolean;
      exp: number;
      iat: number;
      iss: string;
      sub: string;
      token_use: string;
    };
  };
  refreshToken: {
    token: string;
  }
  clockDrift: number;
}

let appConfig: AppConfig;

const loadConfig = async () => {
  if(appConfig === undefined){
    const appConfigRequest = await fetch('/tenant/app-config.json');
    appConfig = await appConfigRequest.json();
  }

  return appConfig
}

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = appConfig.userPoolBaseUri.replace('https://', '').replace('http://', '')
  const auth = new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri
  });
  return auth;
}

// Creates a CognitoUser instance
const createCognitoUser = () => {
  const pool = createCognitoUserPool();
  return pool.getCurrentUser();
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = () => new CognitoUserPool({
  UserPoolId: appConfig.userPool,
  ClientId: appConfig.clientId,
});

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = () => {
  const signinUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`;
  return signinUri;
}

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href: string) =>
  new Promise((resolve, reject) => {
    const auth = createCognitoAuth()

    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(new Error('Failure parsing Cognito web response: ' + err));
      }
    }
    auth.parseCognitoWebResponse(href);
  });

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => new Promise((resolve, reject) => {
  const cognitoUser = createCognitoUser();
  cognitoUser?.getSession((err: Error, result: CognitoUserSessionResult) => {
    if (err || !result) {
      reject(new Error('Failure getting Cognito session: ' + err));
      return;
    }

    // Resolve the promise with the session credentials
    console.debug('Successfully got session: ' + JSON.stringify(result));
    const session = {
      credentials: {
        accessToken: result.accessToken.jwtToken,
        idToken: result.idToken.jwtToken,
        refreshToken: result.refreshToken.token
      },
      user: {
        userName: result.idToken.payload['cognito:username'],
        email: result.idToken.payload.email,
      },
    };
    resolve(session);
  });
});

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth();
  //auth.useCodeGrantFlow();
  auth.signOut();
}

export default {
  loadConfig,
  createCognitoAuth,
  createCognitoUser,
  createCognitoUserPool,
  getCognitoSession,
  getCognitoSignInUri,
  parseCognitoWebResponse,
  signOutCognitoSession,
}
