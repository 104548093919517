import React from 'react'

import { podPrivacySettingIcons } from '../../../../../../../../../../constants/icons'
import FooterStat from '../FooterStat'

export type PrivacySettingString = 'public' | 'private' | 'secret'

export interface PrivacySettingPropT {
  value: PrivacySettingString
}

function getPrivacySettingData(value: PrivacySettingString) {
  switch (value) {
    case 'public':
      return {
        text: 'Public',
        icon: podPrivacySettingIcons.public,
      }
    case 'private':
      return {
        text: 'Private',
        icon: podPrivacySettingIcons.private,
      }
    case 'secret':
      return {
        text: 'Secret',
        icon: podPrivacySettingIcons.secret,
      }
  }
}

export default function PrivacySetting({ value }: PrivacySettingPropT) {
  const { icon, text } = getPrivacySettingData(value)

  return (
    <FooterStat icon={icon} text={text} />
  )
}
