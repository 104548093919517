import { Reducer } from 'redux';
import { CLEAR_SESSION, SET_SESSION } from '../constants/actionTypes';
import { SESSION_KEY } from '../constants/storageKeys';

const cachedSessionString = window.localStorage.getItem(SESSION_KEY) || '{}';
const cachedSession = JSON.parse(cachedSessionString);

const initialState = cachedSession?.isLoggedIn
  ? {
      ...cachedSession,
      isLoggedIn: true,
    }
  : {
      isLoggedIn: false,
    };

const session: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...action.session,
        isLoggedIn: true,
      };

    case CLEAR_SESSION:
      return {
        isLoggedIn: false,
      };

    default:
      return state;
  }
}

export default session;
