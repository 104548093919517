import { gql } from 'apollo-boost'

export const companiesList = gql`
query CompaniesList {
  Companies {
    id
    name
  }
}
`

export const companyPods = gql`
query CompanyPods($id: String!) {
  Company(id: $id) {
    podConnection(first: 25) {
      totalCount
      edges {
        node {
          id
          title
          description
          privacy_setting
          conversationConnection {
            totalCount
          }
          playbookConnection {
            totalCount
          }
        }
      }
    }
  }
}
`

export const companyPlaybooks = gql`
query CompanyPlaybooks($companyId: String!) {
  PlayBooks {
    id
    title
    pageConnection(first: 3) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
}
`

export const companyUsers = gql`
query CompanyUsers($id: String!) {
  Company(id: $id) {
    usersConnection(first: 25) {
      totalCount
      edges {
        node {
          id
          username
          email
          status
        }
      }
    }
  }
}
`
