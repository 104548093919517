import { Grid, GridCell } from '@rmwc/grid'
import Loading from '@invotra/invotra-mdc/lib/widgets/Loading'
import React from 'react'

import { usePodsData } from '../../../../../../hooks/graphql/companies'
import AddNewPod from './components/AddNewPod'
import Pod from './components/Pod'

export interface PodsPropT {
  companyId: string
}

export default function Pods({ companyId }: PodsPropT) {
  const {
    loading,
    // totalCount,
    pods,
    refetch,
  } = usePodsData(companyId)

  const defaults = { companyId }

  return (
    <div className="pods-grid">
      <Grid>
        {pods && pods.map((pod, index) => (
          <GridCell
            desktop={6}
            tablet={8}
            phone={4}
          >
            <Pod {...pod} companyId={companyId} refetch={refetch} key={index} />
          </GridCell>
        ))}
        <Loading isLoading={loading} />
        <AddNewPod defaults={defaults} refetch={refetch} />
      </Grid>
    </div>
  )
}
