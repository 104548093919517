import { FormEvent } from 'react'
import { useMutation } from 'react-apollo'

import { createPod, deletePod, updatePod } from '../../graphql/mutations/pods'
import useFormData from '../useFormData'

export function useCreatePod(defaults: any = {}) {
  const { data, get, set } = useFormData({
    title: '',
    description: '',
    privacySetting: 'public',
    ...defaults,
  })

  const [executeMutation, result] = useMutation(createPod)

  const submit = (event: FormEvent) => {
    event.preventDefault()

    return executeMutation({
      variables: data,
    })
  }

  return { submit, get, set, result }
}

export function useUpdatePod(defaults: any = {}) {
  const { data, get, set } = useFormData({
    title: '',
    description: '',
    privacySetting: 'public',
    ...defaults,
  })

  const [executeMutation, result] = useMutation(updatePod)

  const submit = (event: FormEvent) => {
    event.preventDefault()

    return executeMutation({
      variables: data,
    })
  }

  return { submit, get, set, result }
}

export function useDeletePod(podId: string) {
  const [executeMutation, result] = useMutation(deletePod)

  const submit = (event?: FormEvent) => {
    event?.preventDefault()

    return executeMutation({
      variables: { podId }
    })
  }

  return { submit, result }
}
