import MdcFab from '@invotra/invotra-mdc/lib/widgets/Fab'
import { IconPropT } from '@invotra/invotra-mdc/lib/widgets/Icon'
import React, { MouseEvent } from 'react'

export interface FabPropT {
  position?: 'left' | 'right'
  icon: IconPropT
  label?: string
  onClick: (event: MouseEvent) => void
  className?: string
}

export default function Fab({
  position = 'right',
  icon,
  label,
  onClick,
  className,
}: FabPropT) {
  const classes = ['floating-action-button']
  position === 'left'
    ? classes.push('floating-action-button--left')
    : classes.push('floating-action-button--right')
  className && classes.push(className)
  const fabClassName = classes.join(' ')

  return (
    <MdcFab
      label={label}
      icon={icon}
      // @ts-ignore
      onClick={onClick}
      className={fabClassName}
    />
  )
}
