import { InMemoryCache } from 'apollo-boost';
import { CachePersistor } from 'apollo-cache-persist';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './app.scss';
import App from './components/App';
import cognitoUtils from './lib/cognitoUtils';
import * as serviceWorker from './serviceWorker';
import store from './store';

serviceWorker.register();

const SCHEMA_VERSION = '1';
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

(async () => {
  const config = await cognitoUtils.loadConfig();
  const { session } = store.getState();
  const cache = new InMemoryCache();

  if (session.isLoggedIn) {
    const persistor = new CachePersistor({
      cache,
      // @ts-ignore
      storage: window.localStorage,
    });

    const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion) {
      await persistor.restore();
    }
    else {
      await persistor.purge();
      window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }
  }

  ReactDOM.render(
    <Provider store={store}>
      <App config={config} cache={cache} />
    </Provider>,
    document.getElementById('root'),
  );
})();
