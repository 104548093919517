import Dialog from '../../../../../../../common/Dialog'
import React, { useState } from 'react'

import { useUpdatePod } from '../../../../../../../../hooks/graphql/pods'
import PodForm from '../PodForm'

export interface EditPodPropT {
  defaults: {
    defaults: any
    refetch?: Function
  } & any
  editingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  refetch: Function
}

export default function EditPod({
  defaults,
  editingState: [open, setOpen],
  refetch,
}: EditPodPropT) {
  const [formDisabled, setFormDisabled] = useState(false)

  return (
    <Dialog
      title="Edit Pod"
      openState={[open, setOpen]}
    >
      <PodForm
        hook={useUpdatePod}
        defaults={defaults}
        disabledState={[formDisabled, setFormDisabled]}
        refetch={refetch}
        onSubmit={() => setOpen(false)}
      />
    </Dialog>
  )
}
