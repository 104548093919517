import React, { Fragment, useState } from 'react'

import Drawer from '../Drawer'
import { ItemT } from '../Drawer/DrawerItem'
import Fab from '../Fab'
import PaddedArea from '../PaddedArea'
import WorkBar from '../WorkBar'
import PageTitle from '../PageTitle'

interface PageProps {
  title?: string
  drawerTitle?: string
  drawerItems?: ItemT[]
  drawerDismissable?: boolean
  drawerStartsOpen?: boolean
  withoutPadding?: boolean
}

export type PagePropT = React.PropsWithChildren<PageProps>;

export default function Page({
  title,
  drawerTitle,
  drawerItems,
  drawerDismissable = false,
  drawerStartsOpen = !drawerDismissable,
  withoutPadding = false,
  children,
}: PagePropT) {
  const [drawerOpen, setDrawerOpen] = useState(drawerStartsOpen)

  const classes = ['page__main']
  const className = classes.join(' ')

  const fabClasses = ['drawer-toggle-fab']
  drawerOpen && fabClasses.push('drawer-toggle-fab--drawer-open')
  const fabClassName = fabClasses.join(' ')

  const AppContentWrapper = withoutPadding
    ? Fragment
    : PaddedArea

  return (
    <Fragment>
      <WorkBar />
      {drawerItems && (
        <Drawer
          title={drawerTitle}
          items={drawerItems}
          dismissible
          appContent={(
            <div className={className}>
              <AppContentWrapper>
                {title && <PageTitle>{title}</PageTitle>}
                {children}
                {drawerDismissable && (
                  <Fab
                    position="left"
                    icon={{
                      icon: drawerOpen
                        ? 'close'
                        : 'menu',
                    }}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                    className={fabClassName}
                  />
                )}
              </AppContentWrapper>
            </div>
          )}
          open={drawerOpen}
          setOpen={setDrawerOpen}
        />
      )}
    </Fragment>
  );
}
