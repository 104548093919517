import { FormEvent } from 'react'
import { useMutation } from 'react-apollo'

import { createUser, deleteUser, updateUser } from '../../graphql/mutations/users'
import useFormData from '../useFormData'

export function useCreateUser(defaults: any = {}) {
  const { data, get, set } = useFormData({
    username: '',
    email: '',
    ...defaults,
  })

  const [executeMutation, result] = useMutation(createUser)

  const submit = (event: FormEvent) => {
    event.preventDefault()

    return executeMutation({
      variables: data,
    })
  }

  return { submit, get, set, result }
}

export function useUpdateUser(defaults: any = {}) {
  const { data, get, set } = useFormData({
    username: '',
    email: '',
    ...defaults,
  })

  const [executeMutation, result] = useMutation(updateUser)

  const submit = (event: FormEvent) => {
    event.preventDefault()

    return executeMutation({
      variables: data,
    })
  }

  return { submit, get, set, result }
}

export function useDeleteUser(userId: string) {
  const [executeMutation, result] = useMutation(deleteUser)

  const submit = (event?: FormEvent) => {
    event?.preventDefault()

    return executeMutation({
      variables: { userId }
    })
  }

  return { submit, result }
}

