import Typography from '@invotra/invotra-mdc/lib/widgets/Typography';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import logo from '../../../images/logo.svg';
import cognitoUtils from '../../../lib/cognitoUtils';
import { SessionData } from '../../../types/session';
import './style.css';

export default function SignIn() {
  const session = useSelector(({ session }: { session: SessionData }) => session);
  
  if (session.isLoggedIn) {
    return (
      <Redirect to="/callback" />
    );
  }

  return (
    <div className="SignIn">
      <header className="SignIn-header">
        <img src={logo} alt="logo" className="SignIn-logo" />
        <Fragment>
          <Typography use="headline4">
            You are not logged in.
          </Typography>
          <a
            href={cognitoUtils.getCognitoSignInUri()}
            className="SignIn-link"
          >
            Sign in
          </a>
        </Fragment>
      </header>
    </div>
  );
}
