import { gql } from 'apollo-boost'


export const createUser = gql`
mutation createUser(
  $companyId: String!
  $username: String!
  $email: String!
  $status: String
) {
  createUser(
    company: $companyId
    username: $username
    email: $email
    status: $status
  ) {
    id
  }
}
`

export const updateUser = gql`
mutation updateUser(
  $userId: String!
  $username: String!
  $email: String!
  $status: String
) {
  updateUser(
    id: $userId
    username: $username
    email: $email
    status: $status
  ) {
    id
  }
}
`

export const deleteUser = gql`
mutation deleteUser($userId: String!) {
  deleteUser(id: $userId) {
    id
  }
}
`
