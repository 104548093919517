import Icon from '@invotra/invotra-mdc/lib/widgets/Icon'
import Typography from '@invotra/invotra-mdc/lib/widgets/Typography'
import React, { Fragment, PropsWithChildren } from 'react'

interface FooterStatWrapperProps {
  tooltip?: string
}

export type FooterStatWrapperPropT = PropsWithChildren<FooterStatWrapperProps>

export interface BaseFooterStatPropT {
  icon?: string
  text: string | number
}

export interface FooterStatPropT extends BaseFooterStatPropT {
  tooltip?: string
}

export function FooterStatWrapper({
  tooltip,
  children,
}: FooterStatWrapperPropT) {
  return (
    <div className="footer-stat" title={tooltip}>
      {children}
    </div>
  )
}

export function BaseFooterStat({
  icon,
  text,
}: BaseFooterStatPropT) {
  return (
    <Fragment>
      {icon && (
        <Icon
          icon={icon}
          color="rgba(0, 0, 0, 0.6)"
          className="footer-stat__icon"
        />
      )}
      <Typography
        use="body2"
        className="footer-stat__text"
      >
        {text}
      </Typography>
    </Fragment>
  )
}

export default function FooterStat({
  icon,
  text,
  tooltip,
}: FooterStatPropT) {
  return (
    <FooterStatWrapper tooltip={tooltip}>
      <BaseFooterStat icon={icon} text={text} />
    </FooterStatWrapper>
  )
}
