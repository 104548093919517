import Grow from '@invotra/invotra-mdc/lib/decorators/Grow'
import Checkbox from '@invotra/invotra-mdc/lib/widgets/Checkbox'
import Icon from '@invotra/invotra-mdc/lib/widgets/Icon'
import Tooltip from '@invotra/invotra-mdc/lib/widgets/Tooltip'
import Typography from '@invotra/invotra-mdc/lib/widgets/Typography'
import React from 'react'

export interface FormPropT {
  close: () => void
  initialValues: any
}

export interface EditableRowPropT {
  /** Defines if user can select rows in table. */
  selectable?: boolean
  /** Defines if row is selected. */
  selected: boolean
  /** A select event handler. */
  handleSelectClick: (checked: boolean) => void
  /** Defines if row contains hidden form. */
  editable?: boolean
  /** Defines if hidden form is opened. */
  open?: boolean
  /** Opens/closes hidden form. */
  setOpen?: (open: boolean) => void
  /** An object contains row data. */
  cells: Object,
  /** Hidden form component. */
  Form?: React.JSXElementConstructor<FormPropT>
}

/**
 * Editable row, can be used in Table component. Contains plain cell and row elements and hidden form filled with values of each row.
 *
 * **Functional Spec:** https://github.com/invotra/invotra-components-spec/blob/develop/v0.3.0/components/datatable.md
 */
export default function EditableRow({
  selectable,
  selected,
  editable,
  open,
  setOpen,
  cells,
  handleSelectClick,
  Form
}: EditableRowPropT) {
  const keyCellPairs = Object
    .entries(cells)
    .filter(([key]) => key !== 'defaults')
    .filter(([key]) => key !== 'refetch')

  return (
    <>
      <tr className="mdc-data-table__row madonna-simple-row" onClick={() => setOpen && setOpen(!open)}>
        {selectable && (
          <td className="mdc-data-table__cell select-cell">
            <Checkbox checked={selected} onChange={handleSelectClick} aria-label="select row"/>
          </td>
        )}
        {keyCellPairs.map(([key, cell], index) => {
          if (typeof cell === 'boolean') {
            return (
              <Icon icon={cell ? 'done' : 'close'} />
            )
          }
          else {
            return (
              <td className="mdc-data-table__cell" key={`data-table-cell-${index}`}>
                <Tooltip
                  content={(
                    <span className="madonna-table-cell-tooltip">{cell}</span>
                  )}
                  activateOn="hover"
                  align="top"
                >
                  <Typography use="body2">{cell}</Typography>
                </Tooltip>
              </td>
            )
          }
        })}
      </tr>
      {editable && Form && (
        <tr className={`mdc-data-table__row madonna-simple-row collapsible ${open ? '' : 'collapsed'}`}>
          <td className="mdc-data-table__cell collapsible" colSpan={keyCellPairs.length + (selectable ? 1 : 0)}>
            <Grow open={open} timeout={200}>
              <Form close={() => setOpen && setOpen(false)} initialValues={cells}/>
            </Grow>
          </td>
        </tr>
      )}
    </>
  )
}
