import { FiltersT } from '@invotra/invotra-mdc/lib/widgets/table/SortableTableCell'
import Loading from '@invotra/invotra-mdc/lib/widgets/Loading'
import Table from '@invotra/invotra-mdc/lib/widgets/table'
import React, { Fragment, useState } from 'react'
import { useQuery } from 'react-apollo'

import { ItemT } from '../../components/common/Drawer/DrawerItem'
import EditableRow from '../../components/common/EditableRow'
import { PrivacySettingString } from '../../components/pages/CompanyDetails/components/tabs/Pods/components/Pod/components/PrivacySetting'
import AddNewUser from '../../components/pages/CompanyDetails/components/tabs/Users/components/AddNewUser'
import EditUser from '../../components/pages/CompanyDetails/components/tabs/Users/components/EditUser'
import { companiesList, companyPods, companyUsers } from '../../graphql/queries/companies'
import { Edge } from '../../types/graphql'

export interface PodNode {
  id: string
  title: string
  description?: string
  privacy_setting: PrivacySettingString
  conversationConnection: {
    totalCount: number
  }
  playbookConnection: {
    totalCount: number
  }
}

export interface PodListing extends PodNode {
  podId: string
}

export function useDrawerItems() {
  const { data, error, loading, refetch } = useQuery(companiesList)
  const [attempts, setAttempts] = useState(1)
  
  let drawerItems: ItemT[] = []

  if (loading) drawerItems = [{ title: 'Loading companies...'}]
  if (data) {
    drawerItems = data.Companies.map(({ id, name }: any) => ({
      title: name,
      to: {
        pathname: `/companies/${id}`,
        state: {
          id,
          name,
        },
      },
    }))
  }
  if (error && !loading) {
    if (attempts < 3) {
      drawerItems = [{
        title: `An error occurred; attempt ${attempts} of 3`
      }]
      refetch()
      setAttempts(attempts + 1)
    }
    else drawerItems = [{
      title: `Error: ${error.message}`
    }]
  }

  return drawerItems
}

export function usePodsData(companyId: string) {
  const { data, loading, ...result } = useQuery(companyPods, {
    variables: { id: companyId }
  })

  const totalCount = data?.Company.podConnection.totalCount
  const pods: PodListing[] = data?.Company?.podConnection?.edges?.map((edge: Edge<PodNode>) => ({
    podId: edge.node.id,
    ...edge.node,
  }))

  return {
    loading,
    totalCount,
    pods,
    ...result
  }
}

export function useUsersData(companyId: string) {
  const { data, loading, refetch, ...result } = useQuery(companyUsers, {
    variables: { id: companyId },
  })

  const totalCount = data?.Company?.usersConnection?.totalCount
  const edges = data?.Company?.usersConnection?.edges

  const tableHead = [
    {
      title: 'username',
      orderBy: 'username',
    },
    {
      title: 'email',
    },
    {
      title: 'status',
    },
  ]

  const tableFilters: FiltersT = {
    order: 'desc',
    sort: '',
  }

  const tableData = edges?.map(({
    node: {
      id,
      username,
      email,
      status,
    }
  }: any) => ({
    username,
    email,
    status,
    defaults: {
      userId: id,
    },
  }))

  const UsersTable = () => (
    <Fragment>
      {tableData && (
        <Table
          selectable
          editable
          head={tableHead}
          filters={tableFilters}
          data={tableData}
          count={totalCount}
          limit={25}
          offset={0}
          // @ts-ignore
          Form={EditUser}
          handleChangePage={() => {}}
          handleChangeLimit={() => {}}
          handleSort={() => {}}
          renderRow={EditableRow}
        />
      )}
      <Loading isLoading={loading} />
      <AddNewUser
        defaults={{ companyId }}
        refetch={refetch}
      />
    </Fragment>
  )

  return {
    UsersTable,
    data,
    loading,
    refetch,
    ...result,
  }
}
