import Typography from '@invotra/invotra-mdc/lib/widgets/Typography'
import React from 'react'

import CompaniesPage from '../../common/CompaniesPage'

export default function Companies() {
  return (
    <CompaniesPage withPadding title="Select a company">
      <Typography use="body2">
        Select a company from the left sidebar to view a list of its pods.
      </Typography>
    </CompaniesPage>
  )
}
