import { Dialog as RmwcDialog } from '@rmwc/dialog'
import IconButton from '@invotra/invotra-mdc/lib/widgets/IconButton'
import React from 'react'
import Typography from '@invotra/invotra-mdc/lib/widgets/Typography'

interface DialogProps {
  title?: string
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export type DialogPropT = React.PropsWithChildren<DialogProps>

export default function Dialog({
  title,
  openState: [open, setOpen],
  children,
}: DialogPropT) {
  return (
    <RmwcDialog
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      className="dialog"
    >
      <div className="dialog__title-bar">
        <Typography
          use="subtitle1"
          className="dialog__title-bar__title"
        >
          {title}
        </Typography>
        <IconButton
          icon="close"
          // @ts-ignore
          onClick={() => setOpen(false)}
          className="dialog__title-bar__close-button"
        />
      </div>
      <div className="dialog__content">
        {children}
      </div>
    </RmwcDialog>
  )
}
