import { ApolloClient } from 'apollo-boost';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Route, Redirect, Switch } from 'react-router-dom';

import Callback from './pages/Callback';
import Companies from './pages/Companies';
import CompanyDetails from './pages/CompanyDetails';
import HelloWorld from './pages/HelloWorld';
import PodDetails from './pages/PodDetails';
import SignIn from './pages/SignIn';
import SignOut from './pages/SignOut';

interface RoutesProps {
  client?: ApolloClient<any>;
}

export type RoutesPropT = React.PropsWithChildren<RoutesProps>;

export default function Routes({ client }: RoutesPropT) {
  return (
    <Switch>
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/sign-out" component={SignOut} />
      <Route exact path="/callback" component={Callback} />
      {client ? (
        <ApolloProvider client={client}>
          <Redirect exact path="/" to="/companies" />
          <Route exact path="/companies" component={Companies} />
          <Route exact path="/companies/:companyId" component={CompanyDetails} />
          <Route exact path="/companies/:companyId/pods/:podId" component={PodDetails} />
          <Route exact path="/hello-world" component={HelloWorld} />
        </ApolloProvider>
      ) : (
        <Redirect to="/sign-in" />
      )}
    </Switch>
  );
}
