import React, { Fragment } from 'react'
import DrawerItem, { ItemT } from './DrawerItem'

export interface LinkListPropT {
  items?: ItemT[]
}

export const links: ItemT[] = []

export default function LinkList({ items = links }: LinkListPropT) {
  return (
    <Fragment>
      {items.map((item, index) => (
        <DrawerItem {...item} key={index} />
      ))}
    </Fragment>
  )
}
