// import Fab from '@invotra/invotra-mdc/lib/widgets/Fab'
import React, { useState, ComponentType } from 'react'
import { ExecutionResult } from 'react-apollo'

import { actionIcons } from '../../../constants/icons'
import Dialog from '../Dialog'
import Fab from '../Fab'

export interface AddNewPropT {
  defaults: any
  hook: (id: string) => {
    submit: (event: React.FormEvent) => Promise<ExecutionResult<any>>
    get: (key: string, fallback?: any) => any
    set: (key: string) => (event: React.FormEvent) => void
  } & any
  label: string
  refetch?: Function
  FormComponent: ComponentType<any>
}

export default function AddNew({
  defaults,
  hook,
  label,
  refetch,
  FormComponent,
}: AddNewPropT) {
  const [open, setOpen] = React.useState(false)

  const handleSubmit = () => setOpen(false)

  return (
    <React.Fragment>
      <Dialog
        title="Create Pod"
        openState={[open, setOpen]}
      >
        <FormComponent
          defaults={defaults}
          hook={hook}
          disabledState={useState(false as boolean)}
          refetch={refetch}
          onSubmit={handleSubmit}
        />
      </Dialog>
      <Fab
        label={label}
        icon={{
          icon: actionIcons.add
        }}
        // @ts-ignore
        onClick={() => setOpen(true)}
      />
    </React.Fragment>
  )
}
