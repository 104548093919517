import { InMemoryCache, HttpLink } from 'apollo-boost';
import { ApolloClient } from 'apollo-client'
import React from 'react';
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { AppConfig } from '../lib/cognitoUtils';
import { SessionData } from '../types/session';
import Routes from './Routes'

interface AppProps {
  config: AppConfig;
  cache: InMemoryCache;
}

export default function App({ config, cache }: AppProps) {
  const session = useSelector(({ session }: { session: SessionData }) => session);
  let client;

  if (session.isLoggedIn) {
    const link = new HttpLink({
      uri: config.graphqlUri,
      fetch,
      headers: {
        Authorization: `Bearer ${session.credentials?.idToken}`
      },
    });

    client = new ApolloClient({
      cache,
      link,
    });
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
      <Routes client={client} />
    </BrowserRouter>
  );
}
