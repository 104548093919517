import React from 'react';
import { LinkProps } from 'react-router-dom';

import DrawerLink from '../DrawerLink';
import DrawerSection from '../DrawerSection';

export interface ItemT {
  title: string;
  to?: LinkProps['to'];
  items?: ItemT[];
}

export default function DrawerItem({ title, to, items }: ItemT) {
  if (items) {
    return (
      <DrawerSection title={title}>
        {items.map((item, index) => (
          <DrawerItem {...item} key={index} />
        ))}
      </DrawerSection>
    );
  }
  else {
    return (
      <DrawerLink to={to}>{title}</DrawerLink>
    );
  }
}