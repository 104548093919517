import React from 'react'

import { useCreateUser } from '../../../../../../../../hooks/graphql/users'
import AddNew from '../../../../../../../common/AddNew'
import UserForm from '../UserForm'

export interface AddNewPropT {
  defaults: any
  refetch?: Function
}

export default function AddNewUser({ defaults, refetch }: AddNewPropT) {
  return (
    <AddNew
      defaults={defaults}
      hook={useCreateUser}
      label="Add User"
      refetch={refetch}
      FormComponent={UserForm}
    />
  )
}
