import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { entityIcons } from '../../../constants/icons'
import CompaniesPage from '../../common/CompaniesPage'
import TabNavigator, { TabWithIcon } from '../../common/TabNavigator'
import Pages from './components/tabs/Pages'
import Playbooks from './components/tabs/Playbooks'
import Pods from './components/tabs/Pods'
import Users from './components/tabs/Users'

export interface CompanyDetailsParams {
  companyId?: string
}

export type CompanyDetailsPropT = RouteComponentProps<CompanyDetailsParams>

const tabs: TabWithIcon[] = [
  {
    label: 'Pods',
    icon: entityIcons.pod,
    component: Pods,
  },
  {
    label: 'Playbooks',
    icon: entityIcons.playbook,
    component: Playbooks,
  },
  {
    label: 'Pages',
    icon: entityIcons.page,
    component: Pages,
  },
  {
    label: 'Users',
    icon: entityIcons.user,
    component: Users,
  },
]

export default function CompanyDetails({ match }: CompanyDetailsPropT) {
  const { companyId = '' } = match.params

  return (
    <CompaniesPage drawerDismissable>
      <TabNavigator tabs={tabs} companyId={companyId} />
    </CompaniesPage>
  )
}
