import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { entityIcons } from '../../../constants/icons'
import CompaniesPage from '../../common/CompaniesPage'
import TabNavigator, { TabWithIcon } from '../../common/TabNavigator'
import Conversations from './components/tabs/Conversations'
import Members from './components/tabs/Members'
import Playbooks from './components/tabs/Playbooks'
import SubPods from './components/tabs/SubPods'

export interface PodDetailsLocationState {
  id: string
  title: string
}

export type PodDetailsPropT = RouteComponentProps<{}, {}, PodDetailsLocationState>

const tabs: TabWithIcon[] = [
  {
    label: 'Discussions',
    icon: entityIcons.discussion,
    component: Conversations,
  },
  {
    label: 'Playbooks',
    icon: entityIcons.playbook,
    component: Playbooks,
  },
  {
    label: 'Sub-pods',
    icon: entityIcons.pod,
    component: SubPods,
  },
  {
    label: 'Members',
    icon: entityIcons.member,
    component: Members,
  },
]

export default function PodDetails({ location }: PodDetailsPropT) {
  const { id } = location.state

  return (
    <CompaniesPage drawerDismissable>
      <TabNavigator tabs={tabs} podId={id} />
    </CompaniesPage>
  )
}
