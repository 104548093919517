import TabBar from '@invotra/invotra-mdc/lib/widgets/TabBar'
import React, { useState, Fragment, ComponentType } from 'react'

import PaddedArea from '../PaddedArea'

export interface Tab {
  label: string
  component: ComponentType<any>
}

export interface TabWithIcon extends Tab {
  icon: string
}

interface TabNavigatorProps {
  tabs: Tab[] | TabWithIcon[]
}

export type TabNavigatorPropT = TabNavigatorProps & any

export default function TabNavigator({ tabs, ...props }: TabNavigatorPropT) {
  const [currentTab, setCurrentTab] = useState(0)
  const withIcons = 'icon' in tabs[0]
  const Component = tabs[currentTab].component

  return (
    <Fragment>
      <TabBar
        tabs={tabs}
        activeTab={currentTab}
        onActivate={(tab) => setCurrentTab(tab)}
        stacked={withIcons}
      />
      <PaddedArea className="tab-navigator__component-area">
        <Component {...props} />
      </PaddedArea>
    </Fragment>
  )
}
