import {
  Card,
  CardActions,
  CardActionButtons,
  CardActionIcon,
  CardActionIcons,
  CardPrimaryAction,
} from '@rmwc/card'
import Typography from '@invotra/invotra-mdc/lib/widgets/Typography'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { useDeletePod } from '../../../../../../../../hooks/graphql/pods'
import { actionIcons } from '../../../../../../../../constants/icons'
import EditPod from '../EditPod'
import PrivacySetting, { PrivacySettingString } from './components/PrivacySetting'
import FooterStat from './components/FooterStat'

export interface PodPropT {
  companyId: string
  podId: string
  title: string
  description?: string
  privacy_setting: PrivacySettingString
  conversationConnection: {
    totalCount: number
  }
  playbookConnection: {
    totalCount: number
  }
  refetch: Function
}

export default function Pod({
  companyId,
  podId,
  title,
  description,
  privacy_setting: privacySetting,
  conversationConnection: {
    totalCount: discussionCount,
  },
  playbookConnection: {
    totalCount: playbookCount,
  },
  refetch,
}: PodPropT) {
  const history = useHistory()
  const [editing, setEditing] = React.useState(false)
  const { submit: deletePod } = useDeletePod(podId)

  const handleDelete = async () => {
    await deletePod()
    await refetch()
  }

  return (
    <Fragment>
      <Card className="pod-card">
        <CardPrimaryAction
          className="pod-card__primary-action"
          onClick={() => history.push(`/companies/${companyId}/pods/${podId}`, {
            id: podId,
            title
          })}
        >
          <Typography use="headline5">
            {title}
          </Typography>
          <Typography use="body2">
            {description}
          </Typography>
        </CardPrimaryAction>
        <CardActions>
          <CardActionButtons>
            <PrivacySetting value={privacySetting} />
            <FooterStat
              icon="forum"
              text={discussionCount}
              tooltip={`${discussionCount} discussions`}
            />
            <FooterStat
              icon="local_library"
              text={playbookCount}
              tooltip={`${playbookCount} playbooks`}
            />
          </CardActionButtons>
          <CardActionIcons>
            <CardActionIcon
              icon={actionIcons.edit}
              onClick={() => setEditing(true)}
            />
            <CardActionIcon
              icon={actionIcons.delete}
              onClick={handleDelete}
            />
          </CardActionIcons>
        </CardActions>
      </Card>
      <EditPod
        defaults={{
          podId: podId,
          title,
          description,
          privacySetting,
        }}
        editingState={[editing, setEditing]}
        refetch={refetch}
      />
    </Fragment>
  )
}
