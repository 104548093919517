import Typography, { TypographyPropT } from '@invotra/invotra-mdc/lib/widgets/Typography'
import React from 'react'

export type PageTitlePropT = Partial<TypographyPropT>

export default function PageTitle({ children }: PageTitlePropT) {
  return (
    <Typography use="headline4">{children}</Typography>
  )
}
