import React from 'react'
import { useUsersData } from '../../../../../../hooks/graphql/companies'

export interface UsersPropT {
  companyId: string
}

export default function Users({ companyId }: UsersPropT) {
  const { UsersTable } = useUsersData(companyId)

  return (
    <UsersTable />
  )
}
