import Button from '@invotra/invotra-mdc/lib/widgets/Button'
import React, { useState } from 'react'

import { useUpdateUser, useDeleteUser } from '../../../../../../../../hooks/graphql/users'
import UserForm from '../UserForm'

export interface EditUserPropT {
  initialValues: {
    defaults: any
    refetch?: Function
  } & any
  close(): void
}

export default function EditUser({ initialValues, close }: EditUserPropT) {
  const [formDisabled, setFormDisabled] = useState(false)
  const { submit: deleteUser } = useDeleteUser(initialValues.defaults.userId)

  const handleUpdate = () => close()
  const handleDelete = async () => {
    setFormDisabled(true)
    await deleteUser()
    initialValues.refetch && await initialValues.refetch()
    close()
    setFormDisabled(false)
  }

  return (
    <React.Fragment>
      <UserForm
        hook={useUpdateUser}
        defaults={initialValues.defaults}
        disabledState={[formDisabled, setFormDisabled]}
        refetch={initialValues.refetch}
        onSubmit={handleUpdate}
      />
      <Button
        disabled={formDisabled}
        label="Delete"
        onClick={handleDelete}
      />
    </React.Fragment>
  )
}
