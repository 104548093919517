import MdcDrawer from '@invotra/invotra-mdc/lib/widgets/Drawer';
import React, { Fragment } from 'react';

import DrawerItem, { ItemT } from './DrawerItem';
import LinkList from './LinkList';

// TODO: Have this exported from invotra-mdc/lib/widgets/Drawer; import it here
interface MdcDrawerProps {
  /** Whether the drawer is open. */
  open: boolean;
  /** Toggle event handler */
  setOpen: (open: boolean) => void;
  /** optional param, can be used in dismissible mode */
  appContent?: React.ReactElement;
  /** Drawer subtitle */
  subTitle?: string;
  /** Whether the drawer is permanently open or can be hidden */
  dismissible?: boolean;
}

interface DrawerProps extends Partial<MdcDrawerProps> {
  title?: string;
  items?: ItemT[];
}

export default function Drawer({
  title = '',
  items,
  open = true,
  setOpen = () => {},
  ...props
}: DrawerProps) {
  const list = {
    children: (
      <Fragment>
        <LinkList items={items} />
        <div className="invotra-pods__drawer__footer">
          <DrawerItem title="Sign Out" to="/sign-out" />
        </div>
      </Fragment>
    ),
  }

  return (
    <div className="invotra-pods__drawer">
      <MdcDrawer
        open={open}
        list={list}
        id={`${title}-app-navigation`}
        title={title}
        setOpen={setOpen}
        {...props}
      />
    </div>
  );
}
