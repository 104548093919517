import Button from '@invotra/invotra-mdc/lib/widgets/Button'
import Select from '@invotra/invotra-mdc/lib/widgets/Select'
import TextField from '@invotra/invotra-mdc/lib/widgets/TextField'
import React from 'react'
import { ExecutionResult } from 'react-apollo'

export interface UserFormPropT {
  defaults: any
  hook: (defaults: any) => {
    submit: (event: React.FormEvent) => Promise<ExecutionResult<any>>
    get: (key: string, fallback?: any) => any
    set: (key: string) => (event: React.FormEvent) => void
  } & any
  disabledState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  refetch?: Function
  onSubmit?: Function
}

export default function UserForm({
  defaults,
  hook,
  disabledState: [formDisabled, setFormDisabled],
  refetch,
  onSubmit,
}: UserFormPropT) {
  const { submit, get, set } = hook(defaults)

  const handleSubmit = async (event: React.FormEvent) => {
    setFormDisabled(true)
    await submit(event)
    refetch && await refetch()
    onSubmit && onSubmit()
    setFormDisabled(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Username"
        value={get('username')}
        // @ts-ignore
        onChange={set('username')}
        disabled={formDisabled}
      />
      <TextField
        label="Email"
        value={get('email')}
        // @ts-ignore
        onChange={set('email')}
        disabled={formDisabled}
      />
      <Select
        label="Status"
        options={['Active', 'Blocked']}
        value={get('status')}
        onChange={set('status')}
        disabled={formDisabled}
      />
      <Button
        // @ts-ignore
        type="submit"
        label="Submit"
        disabled={formDisabled}
      />
    </form>
  )
}
