export const actionIcons = {
  add: 'add',
  delete: 'delete',
  edit: 'create',
}

export const entityIcons = {
  discussion: 'forum',
  member: 'group',
  page: 'description',
  playbook: 'local_library',
  pod: 'group_work',
  user: 'account_circle',
}

export const podPrivacySettingIcons = {
  public: 'lock_open',
  private: 'lock',
  secret: 'block',
}
