import { useState } from 'react'

export default function useFormData<T = any>(initialData: T) {
  const [data, setData] = useState<T>(initialData)
  
  return {
    data,
    get: (key: keyof T, fallback?: any) =>
      key in data
        ? data[key]
        : fallback,
    set: (key: keyof T) => (event: React.FormEvent<HTMLInputElement>) => {
      setData({
        ...data,
        [key]: event.currentTarget.value,
      })
    },
    setData,
  }
}
