import Button from '@invotra/invotra-mdc/lib/widgets/Button'
import Select from '@invotra/invotra-mdc/lib/widgets/Select'
import TextField from '@invotra/invotra-mdc/lib/widgets/TextField'
import React from 'react'
import { ExecutionResult } from 'react-apollo'

export interface PodFormPropT {
  defaults: any
  hook: (defaults: any) => {
    submit: (event: React.FormEvent) => Promise<ExecutionResult<any>>
    get: (key: string, fallback?: any) => any
    set: (key: string) => (event: React.FormEvent) => void
  } & any
  disabledState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  refetch?: Function
  onSubmit?: Function
}

export default function PodForm({
  defaults,
  hook,
  disabledState: [formDisabled, setFormDisabled],
  refetch,
  onSubmit,
}: PodFormPropT) {
  const { submit, get, set } = hook(defaults)

  const handleSubmit = async (event: React.FormEvent) => {
    setFormDisabled(true)
    await submit(event)
    refetch && await refetch()
    onSubmit && onSubmit()
    setFormDisabled(false)
  }

  return (
    <form onSubmit={handleSubmit} className="pod-form">
      <TextField
        outlined
        label="Title"
        value={get('title')}
        // @ts-ignore
        onChange={set('title')}
        disabled={formDisabled}
        className="pod-form__line"
      />
      <TextField
        outlined
        fullwidth
        textarea
        label="Description"
        value={get('description')}
        // @ts-ignore
        onChange={set('description')}
        disabled={formDisabled}
        className="pod-form__line"
      />
      <Select
        outlined
        label="Privacy Setting"
        options={[
          {
            value: 'public',
            label: 'Public',
          },
          {
            value: 'private',
            label: 'Private',
          },
          {
            value: 'secret',
            label: 'Secret',
          },
        ]}
        value={get('privacySetting')}
        onChange={set('privacySetting')}
        disabled={formDisabled}
        className="pod-form__line"
      />
      <Button
        raised
        // @ts-ignore
        type="submit"
        label="Submit"
        disabled={formDisabled}
        className="pod-form__float-right"
      />
    </form>
  )
}
