import * as React from 'react'

interface Props {
  className?: string
}

export type PaddedAreaProps = React.PropsWithChildren<Props>

export default function PaddedArea({
  className = '',
  children
}: PaddedAreaProps) {
  const defaultClassName = 'invotra-admin__padded-area'

  return (
    <div className={`${className} ${defaultClassName}`}>
      {children}
    </div>
  )
}
